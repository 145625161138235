


















import { computed, defineComponent } from '@nuxtjs/composition-api';
import { useVSFContext } from '@vue-storefront/core';
import type { PropType } from '@nuxtjs/composition-api';
import type { CurrentRefinementItem } from 'vue-instantsearch';
import { ALGOLIA_ATTRIBUTE, MAX_STARS } from '~/constants/algolia';
import { useAlgolia, useIntegrations, usePageEventLocation, useCategories } from '~/composables';
import { EVENT_SOURCE } from '~/constants/googleTagManager';

export default defineComponent({
  name: 'CurrentRefinementLabel',
  props: {
    refinement: {
      type: Object as PropType<CurrentRefinementItem>,
      required: true,
    },
    createUrl: {
      type: Function as PropType<(refinement: CurrentRefinementItem) => string>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { getAlgoliaFilterName } = useAlgolia();
    const { route } = useVSFContext();
    const { $tagManager } = useIntegrations();
    const { getCurrentPageType } = usePageEventLocation();
    const {
      getters: {
        currentCategoryName,
      },
    } = useCategories();
    const pageType = getCurrentPageType();
    const categoryPath = pageType === EVENT_SOURCE.CATEGORY_LISTING ? route.value.params.slug : '';
    const categoryName = pageType === EVENT_SOURCE.CATEGORY_LISTING ? currentCategoryName.value : '';

    const currentRefinementLabel = computed(() =>
      getAlgoliaFilterName(props.refinement.label, props.refinement.attribute),
    );

    const refinementUrl = computed(() => props.createUrl(props.refinement));

    const shouldHideLabel = computed(() =>
      props.refinement.attribute === ALGOLIA_ATTRIBUTE.AVG_RATING &&
      props.refinement.value === MAX_STARS,
    );

    const onClick = () => {
      emit('refine', props.refinement);
      $tagManager.events.triggerClearSingleFiltersParameter({
        filterName: props.refinement.label,
        filterValue: props.refinement.value,
        categoryPath,
        categoryName,
        listingType: pageType,
      });
    };

    return {
      currentRefinementLabel,
      refinementUrl,
      shouldHideLabel,
      onClick,
    };
  },
});
