


















import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'QuantitySelectorButton',
  props: {
    isLeft: {
      type: Boolean,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isSmall: {
      type: Boolean,
      default: false,
    },
    isLarge: {
      type: Boolean,
      default: false,
    },
  },
});
