

































































import {
  computed,
  defineComponent,
  toRefs,
  useRoute,
  onMounted,
  watch,
} from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import type { ProductVariant } from '~/utils/ct/types/product/ProductVariant';
import { ProductVariantExtended } from '~/types/product/ProductVariantExtended';
import { PDP_NAV_ANCHORS } from '~/constants/pdpNavAnchors';
import { productGetters } from '~/utils/ct';
import {
  useAddToCart,
  useExtraGuaranteeExcluded,
  useI18n,
  useProductComparison,
  useProductPrice,
  useSiblingCategories,
} from '~/composables';
import { isSecondHandProduct } from '~/helpers/product/getProductInfo/getProductInfo';
import ProductInformationSkeleton from '~/components/molecules/PdpSkeletons/ProductInformationSkeleton.vue';
import RatingsReviewsSkeleton from '~/components/molecules/PdpSkeletons/RatingsReviewsSkeleton.vue';
import ProductAnchorNav from '~/components/molecules/Product/ProductAnchorNav.vue';
import AnchorNavigationSectionWrapper from '~/components/molecules/Product/AnchorNavigationSectionWrapper.vue';
import { ProductReview } from '~/types/product/attribute/ProductReview';
import { ProductProcessedAttributes } from '~/types/product/attribute/ProductProcessedAttributes';
import { getBrandInfo } from '~/helpers/brand';
import getProductDescription from '~/helpers/product/getProductDescription/getProductDescription';
import TrustPilotCarousel from '~/components/molecules/TrustPilot/TrustPilotCarousel.vue';
import FaqSection from '~/components/organisms/Product/FaqSection.vue';

export default defineComponent({
  name: 'ProductAnchorSections',
  components: {
    RatingsReviews: () => import(/* webpackChunkName: "RatingsReviews" */
      '~/components/molecules/Product/RatingsReviews/RatingsReviews.vue'),
    ProductInformation: () => import(/* webpackChunkName: "ProductInformation" */
      '~/components/organisms/Product/ProductInformation.vue'),
    ProductInformationSkeleton,
    RatingsReviewsSkeleton,
    ProductAnchorNav,
    AnchorNavigationSectionWrapper,
    TrustPilotCarousel,
    FaqSection,
    BloomreachLastSeenProducts: () => import(/* webpackChunkName: "BloomreachLastSeenProducts" */
      '~/components/organisms/Product/Bloomreach/BloomreachLastSeenProducts/BloomreachLastSeenProducts.vue'),
  },
  props: {
    product: {
      type: Object as PropType<ProductVariantExtended>,
      default: () => {},
    },
    attributesLocalized: {
      type: Object as PropType<ProductProcessedAttributes>,
      default: () => {},
    },
    reviews: {
      type: Array as PropType<Array<ProductReview>>,
      required: true,
    },
    relatedAccessories: {
      type: Array as PropType<ProductVariant[]>,
      required: true,
    },
    relatedAccessoriesLoading: {
      type: Boolean,
      required: true,
    },
    parentsForAccessory: {
      type: Array as PropType<ProductVariant[]>,
      required: true,
    },
    parentsForAccessoryLoading: {
      type: Boolean,
      required: true,
    },
    shouldShowRelatedAccessories: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const { product, attributesLocalized } = toRefs(props);

    const route = useRoute();
    const { languageAndCountry } = useI18n();
    const { isGuaranteeExcluded } = useExtraGuaranteeExcluded();
    const { getPriceInformation } = useProductPrice();
    const {
      isQuantitySelectionPossible,
    } = useAddToCart(route.value.params.slug, product.value?.sku);

    const { productPrice } = getPriceInformation(product, attributesLocalized);

    const faq = computed(() => props.attributesLocalized?.faq || []);
    const aboutBrand = computed(() => {
      const brandInfo = getBrandInfo(props.attributesLocalized?.brand);
      if (!brandInfo?.url) {
        return;
      }
      return brandInfo;
    });
    const productId = computed(() => productGetters.getId(product.value));
    const description = computed(
      () => getProductDescription(product.value, languageAndCountry.value),
    );

    const shouldShowWarranty = computed(() =>
      product.value?.sku &&
      productPrice.value &&
      isQuantitySelectionPossible.value &&
      !isGuaranteeExcluded(product.value),
    );

    const shouldShowParentsForAccessory = computed(() =>
      props.parentsForAccessory &&
      props.parentsForAccessory.length &&
      !isSecondHandProduct(product.value),
    );

    const { siblingCategories, load: loadSiblingCategories } = useSiblingCategories();

    const productSku = computed(() => product.value?.sku || null);

    const {
      productsData: comparisonProductsData,
      search: searchComparisonProducts,
    } = useProductComparison(productSku.value);

    onMounted(() => {
      if (product.value) {
        loadSiblingCategories(product.value);
        searchComparisonProducts(product.value);
      }
    });

    watch(
      productSku,
      () => {
        const newValue = product.value;
        if (newValue) {
          loadSiblingCategories(newValue);
          searchComparisonProducts(newValue);
        }
      },
    );

    return {
      description,
      faq,
      aboutBrand,
      reviewsCount: computed(() => productGetters.getTotalReviews(product.value)),
      productRating: computed(() => productGetters.getAverageRating(product.value)),
      shouldShowWarranty,
      shouldShowParentsForAccessory,
      PDP_NAV_ANCHORS,
      productId,
      productPrice,
      siblingCategories,
      comparisonProductsData,
    };
  },
});
