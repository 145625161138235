import type { ProductVariant } from '~/utils/ct/types/product/ProductVariant';
import { PRODUCT_ATTRIBUTES } from '~/constants/products';
import { convertBWareToAWare } from '~/helpers/product/convertBWare';
import getProductAttribute from '~/helpers/product/getProductAttribute/getProductAttribute';
import { Product360Image } from '~/types/product/Product360Image';
import { removeProductSkuPrefix } from '~/helpers/product/removeProductSkuPrefix';

export const getProduct360Image = (product: ProductVariant): Product360Image | null => {
  const numberOf360Images = getProductAttribute(product, PRODUCT_ATTRIBUTES.NUMBER_OF_360_IMAGES);
  const numberOfImages = parseInt(numberOf360Images) || 0;

  if (!numberOfImages || !product.sku) {
    return null;
  }

  const productSku = removeProductSkuPrefix(convertBWareToAWare(product.sku));
  const path = `${productSku}_photo_360_{index}`;

  return {
    numberOfImages,
    path,
  };
};
