import type { ProductVariant } from '~/utils/ct/types/product/ProductVariant';
import { getProduct360Image } from '~/helpers/product/getProduct360Image';
import { getProductVideo } from '~/helpers/product/getProductVideo';
import { getProductDescriptionSections } from '~/helpers/product/getProductDescriptionSections';
import getProductAttribute from '~/helpers/product/getProductAttribute/getProductAttribute';
import { PRODUCT_ATTRIBUTES } from '~/constants/products';
import type { ProductDescriptionRaw } from '~/types/product/attribute/ProductDescriptionRaw';
import type { ProductDescription } from '~/types/product/ProductDescription';

export default (product: ProductVariant, languageAndCountry: string): ProductDescription => {
  const descriptionJson = getProductAttribute(product, PRODUCT_ATTRIBUTES.DESCRIPTION, languageAndCountry);

  try {
    const descriptionRaw: ProductDescriptionRaw = descriptionJson ? JSON.parse(descriptionJson) : {};
    if (!descriptionRaw || Object.keys(descriptionRaw).length === 0) {
      return {};
    }

    const threeSixtyImage = getProduct360Image(product);
    const videoInformation = getProductVideo(product, languageAndCountry);
    const sections = getProductDescriptionSections({
      productDescription: descriptionRaw,
      threeSixtyImage,
      videoInformation,
    });

    return {
      sections,
      additional: descriptionRaw.additional,
    };
  } catch (ex) {
    const jsonParsingError =
      `Invalid description JSON for product ${product?.sku} for market ${languageAndCountry}: ${ex}`;
    throw new Error(jsonParsingError);
  }
};
