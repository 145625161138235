
























































import { SfInput } from '@storefront-ui/vue';
import { debounce } from 'lodash';
import { defineComponent, ref, toRefs, watch, computed } from '@nuxtjs/composition-api';
import type { Ref } from '@nuxtjs/composition-api';
import { DEBOUNCE_TIMES } from '~/constants/debounceTimes';
import config from '~/config';
import QuantitySelectorButton from '~/components/atoms/Product/QuantitySelectorButton.vue';
import { QUANTITY_ACTION_TYPE } from '~/constants/googleTagManager';
import type { QuantityActionType } from '~/constants/googleTagManager';
import { useIntegrations } from '~/composables';

export default defineComponent({
  name: 'QuantitySelector',
  components: {
    SfInput,
    QuantitySelectorButton,
  },
  inheritAttrs: false,
  props: {
    /** Quantity */
    qty: {
      type: Number,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    stock: {
      type: Number,
      required: true,
    },
    hasControls: {
      type: Boolean,
      default: false,
    },
    alignLeft: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: 'default',
      validator: (value: string) => {
        return ['default', 'smallButtons', 'largeButtons'].includes(value);
      },
    },
    isDebounced: {
      type: Boolean,
      default: true,
    },
    sku: {
      type: String,
      required: true,
    },
    triggeredIn: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const { qty } = toRefs(props);
    const { $tagManager } = useIntegrations();

    const localQty: Ref<typeof props['qty']> = ref(qty.value);
    const warningOnQuantity = ref<boolean>(false);
    const canIncrement = ref<boolean>(true);
    const canDecrement = ref<boolean>(localQty.value > 1);
    const hasSmallButtons = computed(() => props?.mode === 'smallButtons');
    const hasLargeButtons = computed(() => props?.mode === 'largeButtons');

    const validateInput = (newQuantity: number) => {
      warningOnQuantity.value = false;
      canIncrement.value = true;
      canDecrement.value = true;

      if (newQuantity <= 1 || isNaN(newQuantity)) {
        canDecrement.value = false;
        return 1;
      }

      if (newQuantity > props.stock && props.stock < config.MAX_SINGLE_PRODUCT_CART_QUANTITY) {
        warningOnQuantity.value = true;
        canIncrement.value = false;
        return props.stock;
      } else if (newQuantity > config.MAX_SINGLE_PRODUCT_CART_QUANTITY) {
        warningOnQuantity.value = true;
        canIncrement.value = false;
        // TODO: INSP-337- display information about max quantity to user
        return config.MAX_SINGLE_PRODUCT_CART_QUANTITY;
      }
      return newQuantity;
    };

    const callQuantityChangeEvent = (actionType: QuantityActionType) => {
      $tagManager.events.triggerQuantityChangeTags({
        sku: props.sku,
        pageCategory: props.triggeredIn,
        actionType,
        selectedQuantity: localQty.value,
      });
    };

    const modifyQuantityWithValidationNoDebounce = (newQuantity: number, actionType: QuantityActionType) => {
      localQty.value = validateInput(newQuantity);
      callQuantityChangeEvent(actionType);
      emit('input', localQty.value);
    };
    const modifyQuantityWithValidation = debounce(
      modifyQuantityWithValidationNoDebounce,
      DEBOUNCE_TIMES.NUMBER_INPUT,
    );

    const inputQty = (input: string) => {
      if (!props.isDebounced) {
        return;
      }
      localQty.value = parseInt(input);
      modifyQuantityWithValidation(localQty.value, QUANTITY_ACTION_TYPE.TEXT_INPUT);
    };

    const blurQty = (input: HTMLInputElement) => {
      if (props.isDebounced) {
        return;
      }
      const newAmount = parseInt(input.value);
      modifyQuantityWithValidationNoDebounce(newAmount, QUANTITY_ACTION_TYPE.TEXT_INPUT);
    };

    const addQty = (amount: number) => {
      localQty.value += amount;
      modifyQuantityWithValidationNoDebounce(
        localQty.value,
        amount > 0 ? QUANTITY_ACTION_TYPE.INCREMENT : QUANTITY_ACTION_TYPE.DECREMENT,
      );
    };

    watch(qty,
      () => {
        if (qty.value !== localQty.value) {
          localQty.value = qty.value;
        }
      },
      { deep: true });

    return {
      warningOnQuantity,
      localQty,
      validateInput,
      inputQty,
      blurQty,
      addQty,
      canIncrement,
      canDecrement,
      hasSmallButtons,
      hasLargeButtons,
    };
  },
});
